import React from "react";
import { Link } from "react-router-dom";

export default class CHeader extends React.Component {

    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-lg bg-success text-white">
                    <div className="container-fluid">
                        <Link className="navbar-brand text-light" to="/">
                            GLI
                        </Link>
                        <button className="navbar-toggler border-0 hamburger hamburger--vortex" type="button" data-bs-toggle="collapse" data-bs-target="#top-menu" aria-controls="top-menu" aria-expanded="false" aria-label="Afficher ou masquer le menu">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        <div className="collapse navbar-collapse" id="top-menu">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Accueil</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/services">Nos services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/offre">Rechercher un profil</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">Nous contacter</Link>
                                </li>
                            </ul>
                            <div className="mb-3 mb-lg-0">
                                <Link className="btn btn-outline-light rounded-0 me-1" to="/offre">Déposer une offre</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}