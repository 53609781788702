import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";

export default class PBanqueCV extends React.Component {

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section className="container-fluid py-3">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="border-bottom pb-3">Banque de CV</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 my-3">
                                <p>Vous pouvez nous contacter de quatre façons.</p>
                                <form action="" className="form row mb-3">
                                    <div className="form-group col-6 mt-3">
                                        <label for="personFirstname">Prénom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="personFirstname" id="personFirstname" placeholder="Votre Prénom" required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label for="personName">Nom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="personName" id="personName" placeholder="Votre Nom" required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label for="personPhone">Téléphone <sup>*</sup> : </label>
                                        <input type="number" className="form-control rounded-0" name="personPhone" id="personPhone" placeholder="Numéro de téléphone" required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label for="personAddress">Adresse <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="personAddress" id="personAddress" placeholder="Adresse" required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label for="personCity">Ville <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="personCity" id="personCity" placeholder="Ville" />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label for="company">Entreprise : </label>
                                        <input type="text" className="form-control rounded-0" name="company" id="company" placeholder="Nom de votre entreprise (optionel)" />
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label for="message">Message <sup>*</sup> : </label>
                                        <textarea type="text" className="form-control rounded-0" name="message" id="message" placeholder="Entrez une bonne description de votre besoin ou de votre requête." rows="8" required></textarea>
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label for="file">Joindre un fichier (si nécessaire) : </label>
                                        <input type="file" className="form-control rounded-0" name="file" id="file" />
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-7">
                            </div>
                        </div>
                    </section>
                </main>
                <CFooter />
            </>
        );
    }
}