import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";
import { Link } from "react-router-dom";

export default class PServices extends React.Component {

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section className="container-fluid bg-success text-center text-white py-5 border-top border-light" id="banner">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="my-3">
                                    Nos services
                                </h2>
                                <h3 className="mb-4">
                                    Nous vous aidons à trouver le meilleur profil à l'international 
                                    et nous vous accompagnons dans toutes les démarches de recrutement et d'immigration.
                                </h3>
                            </div>
                        </div>
                    </section>
                    <section className="container-fluid py-3">
                        <div className="row align-item-center">
                            <div className="col-md-12">
                                <div className="d-flex flex-wrap services-list mb-3">
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-folder-closed text-primary"></i>
                                            <h3 className="mt-2">Banque de CV et recherche personnalisée.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-file-circle-check text-secondary"></i>
                                            <h3 className="mt-2">Accompagnement à la recherche du meilleur profil.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-handshake-angle text-success"></i>
                                            <h3 className="mt-2">Accompagnement à l'obtention de l'EIMT.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-helmet-safety text-danger"></i>
                                            <h3 className="mt-2">Branchement pour la demande de l'EIMT.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-headset text-warning"></i>
                                            <h3 className="mt-2">Conseils en immigration des travailleurs.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-business-time text-info"></i>
                                            <h3 className="mt-2">Accompagnement à l'obtention du Permis de Travail.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-plane-departure text-muted"></i>
                                            <h3 className="mt-2">Réservation des billets d'avions.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-person-walking-luggage text-danger"></i>
                                            <h3 className="mt-2">Conseils en préparation et voyage aérien.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-person-shelter text-primary"></i>
                                            <h3 className="mt-2">Réservation d'hôtel et de logement.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-taxi text-warning"></i>
                                            <h3 className="mt-2">Accueil et accompagnement au domicile.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-people-arrows text-success"></i>
                                            <h3 className="mt-2">Conseils en insertion sociale et professionnelle.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-credit-card text-black-50"></i>
                                            <h3 className="mt-2">Conseils en gestion environementale et financière.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container-fluid bg-info text-center py-5">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Facilitez-vous la vie !</h2>
                                <div className="mt-3">
                                    <p>
                                        Voulez-vous recruter de la main-d'œuvre étrangère ?
                                        <br />
                                        Laissez-nous vous aider et cela ne vous coûte aucun frais.
                                    </p>
                                    <Link to="/offre" className="btn btn-outline-dark text-uppercase rounded-0 mt-2">Déposer une offre maintenant</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <CFooter />
            </>
        );
    }
}