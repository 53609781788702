import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";
import { Link } from "react-router-dom";

export default class PAccueil extends React.Component {

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section>
                        <div id="main-slider" className="carousel slide">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#main-slider" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#main-slider" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#main-slider" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src="/images/travailleur-internationaux-dans-l-agro-alimentaire.jpg" className="d-block w-100" alt="Immigrant africains au Canada" />
                                    <div className="carousel-caption d-none d-md-block px-md-5">
                                        <div className="d-flex flex-column px-5 mx-5">
                                            <h1 className="title">GLImm</h1>
                                            <h2 className="description">
                                                La solution des entreprises en matière 
                                                de main d'oeuvre étrangère.
                                            </h2>
                                            <div className="mt-3">
                                                <Link className="btn btn-outline-light rounded-0" to="/offre">Commencer maintenant !</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src="/images/immigrant-travailleur-africain-au-canada.webp" className="d-block w-100" alt="Immigrant travailleurs africains au Canada" />
                                    <div className="carousel-caption d-none d-md-block px-md-5">
                                        <div className="d-flex flex-column px-5 mx-5">
                                            <h2 className="title">La main-d'œuvre idéale à votre porté</h2>
                                            <h3 className="description">
                                                Nous recherchons pour vous, le profil le plus adapté à vos besoins.
                                            </h3>
                                            <div className="mt-3">
                                                <Link className="btn btn-outline-light rounded-0" to="/offre">Déposer une offre</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src="/images/jeune-travailleur-interracial.jpg" className="d-block w-100" alt="Immigrant travailleurs africains au Canada" />
                                    <div className="carousel-caption d-none d-md-block px-md-5">
                                        <div className="d-flex flex-column px-5 mx-5">
                                            <h2 className="title">Recrutez à l'étranger devient plus facile</h2>
                                            <h3 className="description">
                                                Nous planifions les entrevus selon votre disponibilité 
                                                et nous vous connectons à un réseau de travailleurs qualifiés.
                                            </h3>
                                            <div className="mt-3">
                                                <Link className="btn btn-outline-light rounded-0" to="/offre">Faire une soumission</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#main-slider" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#main-slider" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </section>
                    <section className="container-fluid py-3" id="mission">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card card-body">
                                    <h2 className="border-bottom pb-3">Notre mission</h2>
                                    <p className="pt-2">
                                        Aider les entreprises à trouver la main d'œuvre dont ils ont besoin.
                                    </p>
                                </div>
                                <div className="card card-body mt-3">
                                    <h2 className="border-bottom pb-3">Notre vision</h2>
                                    <p className="pt-2">
                                        Faciliter l'immigration aux personnes riches en compétences et en talents.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-8 mt-3 mt-md-0 d-flex">
                                <div className="card card-body flex-auto">
                                    <h2 className="border-bottom pb-3">Que faisons-nous ?</h2>
                                    <p className="pt-2">
                                        En vue de faciliter aux entreprises la recherche de talents et 
                                        aux talents les procédures d'immigrations longues et complexes. 
                                        Nous offrons une variété de services pour une liaison sans difficulté 
                                        entre les entreprises et les travailleurs recrutés.
                                    </p>
                                    <p>
                                        Pour une satisfaction parfaite de nos partenaires,
                                        nous accompagnons les entreprises et les recrues dans toutes les démarches, 
                                        jusqu'à l'arrivée du travailleur au Canada et son installation dans son domicile.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container-fluid bg-success text-center text-white py-5">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="icon-service bg-light text-success rounded-pill mx-auto">
                                    <i className="fa-solid fa-gift"></i>
                                </div>
                                <h2 className="mt-2">
                                    Accompagnement pour l'EIMT et le CAQ
                                </h2>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="icon-service bg-light text-success rounded-pill mx-auto">
                                    <i className="fa-solid fa-hand-holding-dollar"></i>
                                </div>
                                <h2 className="mt-2">
                                    Accompagnement à la demande du Permis de travail
                                </h2>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="icon-service bg-light text-success rounded-pill mx-auto">
                                    <i className="fa-solid fa-headset"></i>
                                </div>
                                <h2 className="mt-2">
                                    Conseils et accompagnement du travailleur
                                </h2>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="icon-service bg-light text-success rounded-pill mx-auto">
                                    <i className="fa-solid fa-bed"></i>
                                </div>
                                <h2 className="mt-2">
                                    Réservation de billet, accueil et logement
                                </h2>
                            </div>
                        </div>
                    </section>
                    <section className="container-fluid py-3">
                        <div className="row align-item-center">
                            <div className="col-md-12">
                                <h2 className="border-bottom pb-3">Nos services</h2>
                                <div className="d-flex flex-wrap services-list mb-3">
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-folder-closed text-primary"></i>
                                            <h3 className="mt-2">Banque de CV et recherche personnalisée.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-file-circle-check text-secondary"></i>
                                            <h3 className="mt-2">Accompagnement à la recherche du meilleur profil.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-handshake-angle text-success"></i>
                                            <h3 className="mt-2">Branchement pour la demande de l'EIMT.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-helmet-safety text-danger"></i>
                                            <h3 className="mt-2">Accompagnement à l'obtention de l'EIMT.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-headset text-warning"></i>
                                            <h3 className="mt-2">Conseils en immigration des travailleurs.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-business-time text-info"></i>
                                            <h3 className="mt-2">Accompagnement à l'obtention du Permis de Travail.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-plane-departure text-muted"></i>
                                            <h3 className="mt-2">Réservation des billets d'avions.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-person-walking-luggage text-danger"></i>
                                            <h3 className="mt-2">Conseils en préparation et voyage aérien.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-person-shelter text-primary"></i>
                                            <h3 className="mt-2">Réservation d'hôtel et de logement.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-taxi text-warning"></i>
                                            <h3 className="mt-2">Accueil et accompagnement au domicile.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-people-arrows text-success"></i>
                                            <h3 className="mt-2">Conseils en insertion sociale et professionnelle.</h3>
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-3 col-xl-2 px-3 mt-3 d-flex flex-column">
                                        <div className="card card-body text-center">
                                            <i className="fa-solid fa-credit-card text-black-50"></i>
                                            <h3 className="mt-2">Conseils en gestion environnementale et financière.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="container-fluid bg-info text-center py-5">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Simplifiez-vous la vie !</h2>
                                <div className="mt-3">
                                    <p>
                                        Voulez-vous recruter de la main-d'œuvre étrangère ?
                                        <br />
                                        Laissez-nous vous aider et cela ne vous coûte aucun frais.
                                    </p>
                                    <Link to="/offre" className="btn btn-outline-dark text-uppercase rounded-0 mt-2">Déposer une offre maintenant</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <CFooter />
            </>
        );
    }
}