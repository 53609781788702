import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";
import { Link } from "react-router-dom";

export default class POffre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            person: {
                fisrtName: "",
                name: "",
                company: "",
                city: "",
                address: "",
                postalCode: "",
                phone: "",
                email: "",
                jobTitle: "",
                experience: 0,
                activity: "",
                salary: "",
                housing: "",
                housingCost: "",
                message: ""
            },
            isProcessing: false,
            toast: {
                show: false,
                title: "Notification",
                message: "",
                type: ""
            }
        }
    }

    handleFormOffer(e) {
        e.preventDefault();
        const fd = new FormData(e.target);
        this.setState({isProcessing: true});
        fetch("https://api.glimm.ca/api/offre", {
            method: "POST",
            body: fd
        })
        .then(response => response.json())
        .then(response => {
            if (response.error) throw response;
            this.handleToastNotify(response.message, "Notification", "success");
        })
        .catch(error => {
            this.handleToastNotify("L'opération à échouée!\n" + error.message, "Erreur !", "error");
        })
        .finally(() => {
            this.setState({
                isProcessing: false
            });
        });
    }

    handleChange(e) {
        const person = this.state.person;
        person[e.target.name] = e.target.value;
        this.setState({
            person: person
        });
    }

    handleToastNotify(message, title="Notification", type="info") {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message,
                type: type
            }
        });
    }

    handleCloseToast() {
        const toast = this.state.toast;
        toast.show = false;
        this.setState(toast);
    }

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section className="container-fluid py-3">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="border-bottom pb-3">Nous soumettre une offre de recrutement</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 my-3">
                                <p>
                                    Vous souhaittez nous sommettre une offre de recrutement 
                                    afin que nous vous proposont les candidats idéales 
                                    qui répondent au profil recherché ?
                                    <br />
                                    Vous êtes au bon endroit !
                                </p>
                                <p>
                                    Remplissez le formilaire suivant pour nous renseigner 
                                    sur le profil que vous recherchez.
                                </p>
                            </div>
                            <div className="col-md-7">
                                <form action="" className="row mb-3" onSubmit={(e) => this.handleFormOffer(e)}>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personFirstname">Prénom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="firstName" id="personFirstname" placeholder="Votre Prénom" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personName">Nom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="name" id="personName" placeholder="Votre Nom" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="company">Entreprise : </label>
                                        <input type="text" className="form-control rounded-0" name="company" id="company" placeholder="Nom de votre entreprise (optionel)" onInput={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personCity">Ville <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="city" id="personCity" placeholder="Ville" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personAddress">Adresse <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="address" id="personAddress" placeholder="Adresse" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personPostalCode">Code postal <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="postalCode" id="personPostalCode" placeholder="Code postal" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personPhone">Téléphone <sup>*</sup> : </label>
                                        <input type="number" className="form-control rounded-0" name="phone" id="personPhone" placeholder="Numéro de téléphone" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personEmail">Email <sup>*</sup> : </label>
                                        <input type="email" className="form-control rounded-0" name="email" id="personEmail" placeholder="Email" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="employePoste">Poste recherché <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="jobTitle" id="employePoste" placeholder="Poste recherché" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="employeExperiences">Expérience minimum : </label>
                                        <input type="text" className="form-control rounded-0" name="experience" id="employeExperiences" placeholder="Nombre d'année d'expérience minimum" onInput={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="employeDomain">Domaine <sup>*</sup> : </label>
                                        <select className="form-control rounded-0" name="activity" id="employeDomain" onChange={(e) => this.handleChange(e)} required>
                                            <option value="">Domaine d'activité de l'emploie</option>
                                            <option>Agriculture</option>
                                            <option>Elevage</option>
                                            <option>Entretien et menage</option>
                                            <option>Assistance à domicile</option>
                                            <option>Journalier</option>
                                            <option>Chauffeur</option>
                                            <option>Machiniste</option>
                                            <option>Mecanique</option>
                                            <option>Soudure</option>
                                            <option>Electricité</option>
                                            <option>Electrotechnique</option>
                                            <option>Génie civil</option>
                                            <option>Ingénieur</option>
                                            <option>Electricien</option>
                                            <option>Autre</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="salary">Salaire <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="salary" id="salary" placeholder="Salaire proposé" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="housing">Logement : </label>
                                        <select className="form-control rounded-0" name="housing" id="housing" onChange={(e) => this.handleChange(e)} required>
                                            <option value="">Option de logement</option>
                                            <option>J'ai un logement conforme prévu pour le ou les travailleurs étrangés</option>
                                            <option>Je souhaite que GLImm trouve un logement conforme pour le ou les travailleurs étrangés</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="housingCost">Coût du logement (si offert) : </label>
                                        <input type="text" className="form-control rounded-0" name="housingCost" id="housingCost" placeholder="Coût du logement (si offert par l'employeur)" onInput={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="welcoming">Accueil : </label>
                                        <select className="form-control rounded-0" name="welcoming" id="welcoming" onChange={(e) => this.handleChange(e)}>
                                            <option value="">Option d'accueil</option>
                                            <option>Je dispose d'un service prévu pour accueillir le ou les travailleurs étrangés</option>
                                            <option>Je souhaite que GLImm se charge d'accueillir le ou les travailleurs étrangés</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="welcomingDay">Préférence d'accueil : </label>
                                        <select className="form-control rounded-0" name="welcomingDay" id="welcomingDay" onChange={(e) => this.handleChange(e)} defaultValue={"Aucune préférence particulière"}>
                                            <option>Aucune préférence particulière</option>
                                            <option>De préférence du lundi au vendredi</option>
                                            <option>De préférence le samedi ou dimanche</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label htmlFor="message">Message <sup>*</sup> : </label>
                                        <textarea type="text" className="form-control rounded-0" name="message" id="message" placeholder="Entrez une bonne description de votre besoin ou de votre requête." rows="8" onInput={(e) => this.handleChange(e)} required></textarea>
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label htmlFor="file">Joindre un fichier (si nécessaire) : </label>
                                        <input type="file" className="form-control rounded-0" name="file[]" id="file" />
                                    </div>
                                    <div className="form-group col-12 mt-3 custom-field">
                                        <input type="checkbox" className="custom-checkbox d-inline" name="concent" id="concent" required />
                                        <label htmlFor="concent">
                                            <sup>*</sup> 
                                            J'accepte que mes information soient utilisées par GLImm pour rechercher un travailleur correspondant avec mes besoins 
                                            et j'accepte que GLImm communique mes informations à ses partenaires dans le cadre de cette mission.
                                        </label>
                                    </div>
                                    <div className="form-group mt-3">
                                        <button className="btn btn-success rounded-0" type="submit" disabled={this.state.isProcessing}>
                                            {this.state.isProcessing && <i className="fa-solid fa-spinner me-2"></i>}
                                            Soumettre la demande d'offre
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    { this.state.toast.show &&
                    <div className="toast-container position-fixed bottom-0 end-0 p-3">
                        <div className={"toast show alert alert-" + this.state.toast.type} role="alert" aria-live="assertive" aria-atomic="true">
                            <div className="toast-header">
                                <strong className="me-auto">{ this.state.toast.title }</strong>
                                <small>...</small>
                                <button type="button" className="btn-close" onClick={() => this.handleCloseToast()}></button>
                            </div>
                            <div className="toast-body">
                                { this.state.toast.message }
                            </div>
                        </div>
                    </div>
                    }
                </main>
                <CFooter />
            </>
        );
    }
}